import { encode } from 'base32';

const hasPrice = (product) =>
	Boolean(product.itemsPerFormat) &&
	Boolean(product.sellerPricePerItem) &&
	Boolean(product.itemsPerFormat);

const currencyToSymbol = (currency) => {
	currency = currency.toUpperCase().trim();

	const Map = [
		['BGN', 'лв'],
		['CHF', 'CHF'],
		['CZK', 'Kč '],
		['DKK', 'kr'],
		['EUR', '€'],
		['GBP', '£'],
		['HRK', 'kn'],
		['GEL', '₾'],
		['HUF', 'ft'],
		['NOK', 'kr'],
		['PLN', 'zł'],
		['RUB', '₽'],
		['RON', 'lei'],
		['SEK', 'kr'],
		['TRY', '₺'],
		['UAH', '₴'],
		['AED', 'د.إ'],
		['ILS', '₪'],
		['KES', 'Ksh'],
		['MAD', '.د.م'],
		['NGN', '₦'],
		['ZAR', 'R'],
		['BRL', 'R$'],
		['CAD', '$'],
		['CLP', '$'],
		['COP', '$'],
		['MXN', '$'],
		['PEN', 'S/.'],
		['USD', '$'],
		['AUD', '$'],
		['BDT', '৳'],
		['CNY', '¥ /元'],
		['HKD', '$ / HK$ / “元”'],
		['IDR', 'Rp'],
		['INR', '₹'],
		['JPY', '¥'],
		['MYR', 'RM'],
		['NZD', '$'],
		['PHP', '₱'],
		['PKR', 'Rs'],
		['GD', '$'],
		['KRW', '₩'],
		['LKR', 'Rs'],
		['THB', '฿'],
		['VND', '₫'],
		['BTC', '₿'],
		['XBT', '₿'],
		['XRP', 'XRP'],
		['XMR', 'ɱ'],
		['LTC', 'Ł'],
		['ETH', 'Ξ'],
	];

	// recupero pair da sigla
	const pair = Map.filter(function(item) {
		return item[0] === currency;
	});

	if (pair.length < 1) return currency;

	// verifico simboli uguali
	const siblings = Map.filter(function(item) {
		return item[1] === pair[0][1];
	});

	if (siblings.length > 1) {
		return currency;
	}

	return pair[0][1];
};



const getMatchingEvent = (hash, events) => {
	let i;
	for (i = 0; i < events.length; i += 1) {
		const base32 = encode(events[i].localId);
		const firstPart = base32.substr(0, 2);
		const secondPart = parseInt(base32, 32) % 32;
		const test = firstPart + secondPart;
		console.log(events[i].name, '->', test);
		if (test === hash) {
			return events[i];
		}
	}
	return null;
};

const encodeEventLocalId = (localId) => {
	const base32 = encode(localId);
	const firstPart = base32.substr(0, 2);
	const secondPart = parseInt(base32, 32) % 32;
	return firstPart + secondPart;
};

export { hasPrice, getMatchingEvent, encodeEventLocalId, currencyToSymbol };
