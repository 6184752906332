import React
//, { useState, useRef, useEffect } 
 from 'react';
//import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import gsap from 'gsap';
import { TweenMax,
	//TweenLite, 
	ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

export const NavBar = ({ pageState, setPageState }) => {
	const scrollToSection = (sectionName) => {
		const sections = document.getElementsByTagName('section');
		for (let i = 0; i < sections.length; i++) {
			if (sections[i].getAttribute('data-sectionname') === sectionName) {
				TweenMax.to(window, 1, {
					scrollTo: {
						y: sections[i],
						x: 0,
						offsetY: document.getElementById('fixedHeader').offsetHeight,
						autoKill: false,
					},
					ease: 'power3.out',
					onComplete: function() {},
				});

				break;
			}
		}
	};
	return (
		<>
			<div id="navBar">
				<div className="items">
					<div
						className={
							pageState.currentSection === 'productIntro'
								? 'item selected'
								: 'item'
						}
						onClick={() => {
							scrollToSection('productIntro');
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="512"
							height="512"
							viewBox="0 0 512 512"
						>
							<path
								d="M448 341.4V170.6A32 32 0 0 0 432.1 143l-152-88.5a47.9 47.9 0 0 0-48.2 0L79.9 143A32 32 0 0 0 64 170.6V341.4A32 32 0 0 0 79.9 369l152 88.5a48 48 0 0 0 48.2 0l152-88.5A32 32 0 0 0 448 341.4Z"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<polyline
								points="69 154 256 264 443 154"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<line
								x1="256"
								y1="464"
								x2="256"
								y2="264"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
						</svg>
						<span>Product</span>
					</div>
					<div
						className={
							pageState.currentSection === 'productGallery'
								? 'item selected'
								: 'item'
						}
						onClick={() => {
							scrollToSection('productGallery');
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="512"
							height="512"
							viewBox="0 0 512 512"
						>
							<path
								d="M432 112V96a48.1 48.1 0 0 0-48-48H64A48.1 48.1 0 0 0 16 96V352a48.1 48.1 0 0 0 48 48H80"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<rect
								x="96"
								y="128"
								width="400"
								height="336"
								rx="46"
								ry="46"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<ellipse
								cx="372.9"
								cy="219.6"
								rx="30.8"
								ry="30.6"
								style={{
									fill: 'none',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<path
								d="M342.2 372.2 255 285.8a30.9 30.9 0 0 0-42.2-1.2L96 387.6"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<path
								d="M265.2 464 383.8 346.3a31 31 0 0 1 41.5-1.9L496 402.9"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
						</svg>
						<span>Gallery</span>
					</div>
					<div
						className={
							pageState.hasPrice
								? pageState.currentSection === 'productPrice'
									? 'item selected'
									: 'item'
								: 'item disabled'
						}
						onClick={() => {
							scrollToSection('productPrice');
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="512"
							height="512"
							viewBox="0 0 512 512"
						>
							<rect
								x="112"
								y="48"
								width="288"
								height="416"
								rx="32"
								ry="32"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<rect
								x="160"
								y="112"
								width="192"
								height="64"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<circle cx="168" cy="248" r="24" className="fill" />
							<circle cx="256" cy="248" r="24" className="fill" />
							<circle cx="344" cy="248" r="24" className="fill" />
							<circle cx="168" cy="328" r="24" className="fill" />
							<circle cx="256" cy="328" r="24" className="fill" />
							<circle cx="168" cy="408" r="24" className="fill" />
							<circle cx="256" cy="408" r="24" className="fill" />
							<rect
								x="320"
								y="304"
								width="48"
								height="128"
								rx="24"
								ry="24"
								className="fill"
							/>
						</svg>
						<span>Price</span>
					</div>
					<div
						className={
							pageState.currentSection === 'productFAQ'
								? 'item selected'
								: 'item'
						}
						onClick={() => {
							scrollToSection('productFAQ');
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="512"
							height="512"
							viewBox="0 0 512 512"
						>
							<path
								d="M256 80A176 176 0 1 0 432 256 176 176 0 0 0 256 80Z"
								style={{
									fill: 'none',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<path
								d="M200 202.3s0.8-17.5 19.6-32.6C230.7 160.8 244 158.2 256 158c10.9-0.1 20.7 1.7 26.5 4.5 10 4.8 29.5 16.4 29.5 41.1 0 26-17 37.8-36.4 50.8S251 281.4 251 296"
								style={{
									fill: 'none',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<circle cx="250" cy="348" r="20" className="fill" />
						</svg>
						<span>FAQ</span>
					</div>
					<div
						className={
							pageState.currentSection === 'productFeedback'
								? 'item selected'
								: 'item'
						}
						onClick={() => {
							scrollToSection('productFeedback');

							// se presente una sola form, triggero l'evento di click
							if (
								document.getElementsByClassName('productFeedback')
									.length
							) {
								setTimeout(
									() => {
										setPageState({
											...pageState,
											iframeOverlayOpen: true,
										});
									},
									//openOverlayFrame,
									/*function(btns) {
										if (btns.length === 1) {
											var evt = new MouseEvent('click', {
												bubbles: true,
												cancelable: true,
												view: window,
											});
											btns[0].dispatchEvent(evt);
										}
									},*/
									800,
									// document
									// 	.getElementsByClassName('productFeedback')[0]
									// 	.getElementsByClassName('btn'),
								);
							}
						}}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="512"
							height="512"
							viewBox="0 0 512 512"
						>
							<path
								d="M408 64H104a56.2 56.2 0 0 0-56 56V312a56.2 56.2 0 0 0 56 56h40v80l93.7-78.1a8 8 0 0 1 5.1-1.9H408a56.2 56.2 0 0 0 56-56V120A56.2 56.2 0 0 0 408 64Z"
								style={{
									fill: 'none',
									strokeLinejoin: 'round',
									strokeWidth: '30px',
									stroke: '#000',
								}}
								className="stroke"
							/>
							<circle cx="160" cy="216" r="32" className="fill" />
							<circle cx="256" cy="216" r="32" className="fill" />
							<circle cx="352" cy="216" r="32" className="fill" />
						</svg>

						<span>Next Step</span>
					</div>
				</div>
			</div>
		</>
	);
};

/*
VECCHIO METODO

export class Header extends React.Component {
	constructor(props) {
		super(props);

		const { showHome, showLogout } = this.props;

		this.state = {};
	}

	_setter = (fieldName) => (event) =>
		this.setState({ [fieldName]: event.target.value });

	render() {
		return <div id="fixedHeader">lorem ipsum</div>;
	}
}
*/
