import React from 'react';
import { config } from '../../logic/config';

export const Landing = ({ msg, title }) => {
	// render page
	return (
		<>
			<section className="landing">
				<div className="w">
					<img src="/Showcase_logo.svg" 
					  alt=""
					/>
					<h2>{title ? title : 'Welcome'}</h2>
					<p>
						{msg ? (
							msg
						) : (
							<>
								You have to get a direct link to the Showcase You\'re
								attending.
								<br />
								Please contact us for further informations.
							</>
						)}
					</p>
					<a href={'mailto:' + config.supportEmail} className="btn">
						Contact us
					</a>
				</div>
			</section>
		</>
	);
};
