if (!Object.keys) {
	Object.keys = function(obj) {
		if (obj !== Object(obj))
			throw new TypeError('Object.keys called on a non-object');
		var k = [], p;
		for (p in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, p)) {
				k.push(p);
			}
		}
		return k;
	};
}
if (!Object.values) {
	Object.values = function(obj) {
		if (obj !== Object(obj))
			throw new TypeError('Object.values called on a non-object');
		var k = [], p;
		for (p in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, p)) {
				k.push(obj[p]);
			}
		}
		return k;
	};
}
if (!Array.prototype.find) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Array.prototype, 'find', {
		value: function(predicate) {
		 // 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[1];

			// 5. Let k be 0.
			var k = 0;

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return kValue.
				var kValue = o[k];
				if (predicate.call(thisArg, kValue, k, o)) {
					return kValue;
				}
				// e. Increase k by 1.
				k++;
			}

			// 7. Return undefined.
			return undefined;
		},
		configurable: true,
		writable: true
	});
}
if (!Array.prototype.filter) {
	// eslint-disable-next-line no-extend-native
	Array.prototype.filter = function(func, thisArg) {
	//	'use strict';
		if ( ! ((typeof func === typeof function(){} || typeof func === typeof function(){}) && this) )
				throw new TypeError();
		
		var len = this.length >>> 0,
				res = new Array(len), // preallocate array
				t = this, c = 0, i = -1;
		if (thisArg === undefined)
			while (++i !== len)
				// checks to see if the key was set
				if (i in this)
					if (func(t[i], i, t))
						res[c++] = t[i];
		else
			while (++i !== len)
				// checks to see if the key was set
				if (i in this)
					if (func.call(thisArg, t[i], i, t))
						res[c++] = t[i];
		
		res.length = c; // shrink down array to proper size
		return res;
	};
}