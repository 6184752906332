import React, { useRef, useEffect } from 'react';
import { TweenLite } from 'gsap/all';

export const OverlayIframe = ({
	url,
	pageState,
	setPageState,
	dataQuerystring,
}) => {
	const overlayIframeRef = useRef();

	if (dataQuerystring) {
		const args = Object.keys(dataQuerystring)
			.map((key) => `${key}=${encodeURIComponent(dataQuerystring[key])}`)
			.join('&');
		url = url + '?' + args;
	}

	useEffect(() => {
		if (pageState.iframeOverlayOpen === false) {
			// chiudo
			TweenLite.to(overlayIframeRef.current, 0.5, {
				opacity: 0,
				display: 'none',
				onComplete: function() {
					const inner = overlayIframeRef.current.childNodes[0];
					for (let i = 0; i < inner.childNodes.length; i++) {
						if (inner.childNodes[i].tagName.toLowerCase() === 'iframe') {
							inner.childNodes[i].src = inner.childNodes[i].src+'';
						}
					}
				},
			});
		} else {
			// apro
			TweenLite.fromTo(
				overlayIframeRef.current,
				0.5,
				{
					opacity: 0,
					display: 'block',
				},
				{
					opacity: 1,
				},
			);
		}
	}, [pageState.iframeOverlayOpen]);

	return (
		<>
			<section className="overlayIframe" ref={overlayIframeRef}>
				<div className="inner">
					<div
						className="close"
						onClick={() => {
							setPageState({ ...pageState, iframeOverlayOpen: false });
						}}
					>
						<img src="/icons/close.svg" alt=""/>
					</div>
					<iframe src={url} title="Iframe" />
				</div>
			</section>
		</>
	);
};
