import React, { useState, useRef, useEffect, useCallback } from 'react';
import { loadDataSheet } from '../../logic/data';
import { TweenLite } from 'gsap/all';
import Masonry from 'react-masonry-component';
import Vimeo from '@u-wave/react-vimeo';
import { FormControl, FormGroup } from 'react-bootstrap';
import { getUserData, userIsLogged } from '../../logic/user';
import { Redirect } from 'react-router';
import { Header } from '../partials/Header';
import { NavBar } from '../partials/NavBar';
import { Link } from 'react-router-dom';
import {
	hasPrice,
	currencyToSymbol,
	encodeEventLocalId,
} from '../../logic/misc';
import { useSwipeable } from 'react-swipeable';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { OverlayIframe } from '../partials/OverlayIframe';

export const ProductDetail = ({ event, productCode, scrollState }) => {
	// page state
	const [pageState, setPageState] = useState({
		loading: true,
		product: null,
		currentSection: null,
		iframeOverlayOpen: false,
	});
	// company description state
	const [companyDescriptionState, setCompanyDescriptionState] = useState({
		expandable: true,
		open: false,
	});
	// video state
	const [videoState, setVideoState] = useState({
		videoPlaying: false,
		percent: 0,
	});
	// gallery lightbox state
	const [lightboxState, setLightboxState] = useState({
		isOpen: false,
		photoIndex: false,
	});
	// trigger for the header scrolledText to be visible on scrolling
	const [firstSectionLoadedImage, setFirstSectionLoadedImage] = useState(
		false,
	);
	// price simulator state
	const [priceSimulatorInput, setPriceSimulatorInput] = useState({
		currency: '',
		items: 1,
		ldc: 0,
		markup: 0,
		cotscoPercentage: 0,
	});
	const [priceSimulatorOutput, setPriceSimulatorOutput] = useState({
		spi: 0,
		spkg: 0,
		sellerPlusLogisticPrice: 0,
		sellerKgPlusLogisticPrice: 0,
		rpPerItem: 0,
		rpPerKg: 0,
		currencySymbol: '',
	});
	// faq state
	const [faqState, setFaqState] = useState({
		open: null,
	});

	const hash = encodeEventLocalId(event.localId);

	// detect current section (by page.scrolltop)
	useEffect(() => {
		let currentSectionName = null;
		const sections = document.getElementsByTagName('section');
		for (let i = 0; i < sections.length; i++) {
			if (sections[i].getAttribute('data-sectionname')) {
				if (
					sections[i].getBoundingClientRect().y <
					window.innerHeight / 2
				) {
					currentSectionName = sections[i].getAttribute(
						'data-sectionname',
					);
				}
			}
		}
		setPageState({ ...pageState, currentSection: currentSectionName });
	}, [scrollState]); // eslint-disable-line react-hooks/exhaustive-deps

	// price simulator
	const priceSwipeHandlers = useSwipeable({
		onSwipedLeft: (eventData) => {
			setPriceSimulatorInput({
				...priceSimulatorInput,
				currency: event.currency,
			});
		},
		onSwipedRight: (eventData) => {
			setPriceSimulatorInput({
				...priceSimulatorInput,
				currency: event.otherCurrency,
			});
		},
		delta: 10,
		preventDefaultTouchmoveEvent: true,
	});

	useEffect(() => {
		if (pageState.loading) return;

		const currencyRate =
			priceSimulatorInput.currency === event.currency
				? 1
				: Number(event.otherCurrencyRate);

		const currencySymbol =
			priceSimulatorInput.currency === event.currency
				? currencyToSymbol(event.currency)
				: currencyToSymbol(event.otherCurrency);

		const spi =
			Number(pageState.product.sellerPricePerItem.replace(',', '.')) *
			currencyRate;
		const spkg =
			Number(pageState.product.sellerPricePerKG.replace(',', '.')) *
			currencyRate;

		const sellerPlusLogisticPrice =
			spi * (1 + Number(priceSimulatorInput.cotscoPercentage) / 100);
		const sellerKgPlusLogisticPrice =
			spkg * (1 + Number(priceSimulatorInput.cotscoPercentage) / 100);

		const rpPerItem =
			sellerPlusLogisticPrice * (1 + priceSimulatorInput.markup / 100);
		const rpPerKg =
			sellerKgPlusLogisticPrice * (1 + priceSimulatorInput.markup / 100);

		setPriceSimulatorOutput({
			spi: spi,
			spkg: spkg,
			sellerPlusLogisticPrice: sellerPlusLogisticPrice,
			sellerKgPlusLogisticPrice: sellerKgPlusLogisticPrice,
			rpPerItem: rpPerItem,
			rpPerKg: rpPerKg,
			currencySymbol: currencySymbol,
		});

		// check switch currency
		if (currencySwitcheSelectorRef && currencySwitcheSelectorRef.current) {
			if (priceSimulatorInput.currency === event.currency) {
				// first
				TweenLite.to(currencySwitcheSelectorRef.current, 0.3, {
					left: '0%',
				});
			} else {
				// second
				TweenLite.to(currencySwitcheSelectorRef.current, 0.3, {
					left: '50%',
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [priceSimulatorInput, pageState.loading, pageState.product]);
	useEffect(() => {
		if (pageState.loading) return;
		setPriceSimulatorInput({
			...priceSimulatorInput,
			currency: event.currency,
			markup: Number(pageState.product.markup),
			items: Number(pageState.product.itemsPerFormat || 1),
		});
	}, [pageState.loading, pageState.product]); // eslint-disable-line react-hooks/exhaustive-deps

	// ref
	const firstSectionRef = useRef();
	const currencySwitcheSelectorRef = useRef();
	const FAQsectionRef = useRef();
	const companyDescriptionRef = useRef();
	const companyDescriptionOuterRef = useRef();
	const companyDescriptionInnerRef = useRef();
	const companyDescriptionSpacerRef = useRef();

	// product intro
	const handleResize = useCallback(
		(e) => {
			if (
				companyDescriptionRef.current &&
				companyDescriptionOuterRef.current &&
				companyDescriptionInnerRef.current
			) {
				const innerHeight = companyDescriptionInnerRef.current.getBoundingClientRect()
					.height;
				const outerHeight = companyDescriptionOuterRef.current.getBoundingClientRect()
					.height;

				if (!companyDescriptionRef.current.classList.contains('open')) {
					if (innerHeight > outerHeight) {
						setCompanyDescriptionState({
							...companyDescriptionState,
							expandable: true,
						});
					} else {
						setCompanyDescriptionState({
							...companyDescriptionState,
							expandable: false,
						});
					}
				}
			}
		},
		[setCompanyDescriptionState, companyDescriptionState],
	);
	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// check if user is logged
	const isLogged = userIsLogged();
	if (!isLogged) return <Redirect to={'/' + hash + '/'} />;
	const userData = getUserData();

	// current event

	// if it still has to load products from the csv
	if (pageState.product === null) {
		// csv filename
		const csvName = event.tableSheet;

		loadDataSheet(csvName).then((objs) =>
			setPageState({
				...pageState,
				loading: false,
				product: objs.find((p) => p.code === productCode),
				hasPrice: hasPrice(objs.find((p) => p.code === productCode)),
			}),
		);
	}

	// if loading
	if (pageState.loading) return <section>Loading...</section>;

	// commmon
	const product = pageState.product;

	const companyDescriptionExpand = () => {
		const innerHeight = companyDescriptionInnerRef.current.getBoundingClientRect()
			.height;
		const outerHeight = companyDescriptionOuterRef.current.getBoundingClientRect()
			.height;
		setCompanyDescriptionState({
			...companyDescriptionState,
			open: true,
		});
		TweenLite.fromTo(
			companyDescriptionOuterRef.current,
			1,
			{
				height: outerHeight,
			},
			{
				height: innerHeight,
				ease: 'power3.out',
				onComplete: () =>
					TweenLite.set(companyDescriptionOuterRef.current, {
						clearProps: 'height',
					}),
			},
		);
	};
	const companyDescriptionCollapse = () => {
		//const innerHeight = companyDescriptionInnerRef.current.getBoundingClientRect()
		//	.height;
		const outerHeight = companyDescriptionOuterRef.current.getBoundingClientRect()
			.height;
		const spacerHeight = companyDescriptionSpacerRef.current.getBoundingClientRect()
			.height;
		setCompanyDescriptionState({
			...companyDescriptionState,
			open: false,
		});
		TweenLite.fromTo(
			companyDescriptionOuterRef.current,
			1,
			{
				height: outerHeight,
			},
			{
				height: spacerHeight,
				ease: 'power3.out',
				onComplete: () => {
					TweenLite.set(companyDescriptionOuterRef.current, {
						clearProps: 'height',
					});
				},
			},
		);
	};

	// product gallery
	const images = product.images
		? product.images.split(',').filter((url) => url)
		: [];
	const videos = product.videos
		? product.videos.split(',').filter((url) => url)
		: [];

	// FAQ
	const faq = product.faqs
		? product.faqs.split('|').reduce((list, elem, i) => {
				if (i % 2 === 0) {
					list.push({ question: elem });
				} else {
					list[list.length - 1].answer = elem;
				}
				return list;
		  }, [])
		: [];
	const openFAQAccordion = (i) => {
		if (FAQsectionRef.current) {
			const questions = FAQsectionRef.current.querySelectorAll(
				'.question-block',
			);

			const question = questions[i];

			if (faqState.open === i) {
				TweenLite.to(question.querySelectorAll('.expander')[0], 0.4, {
					height: '0px',
					ease: 'power3.out',
				});
				question.classList.remove('open');
				setFaqState({ open: null });
			} else {
				for (let j = 0; j < questions.length; j++) {
					if (j === i) continue;
					TweenLite.to(
						questions[j].querySelectorAll('.expander')[0],
						0.4,
						{
							height: '0px',
							ease: 'power3.out',
						},
					);
					questions[j].classList.remove('open');
				}

				TweenLite.to(question.querySelectorAll('.expander')[0], 0.4, {
					height:
						question.querySelectorAll('.expander span')[0].offsetHeight +
						'px',
					ease: 'power3.out',
				});

				question.classList.add('open');

				setFaqState({ open: i });
			}
		}
	};

	// feedback
	const form = product.typeformUrl;

	// render page
	return (
		<>
			<Header
				showHome={true}
				showLogout={false}
				scrolledText={product.productName}
				event={event}
				scrollState={scrollState}
				videoState={videoState}
				setVideoState={setVideoState}
				partnerLogo={event.partnerLogo}
				scrolledTextHeightItem={firstSectionRef}
				scrolledTextHeightLoaded={firstSectionLoadedImage}
			/>
			<NavBar pageState={pageState} setPageState={setPageState} />
			<section
				className="productIntro"
				ref={firstSectionRef}
				data-sectionname="productIntro"
			>
				<div className="w">
					<div className="img squared">
						<img
							src={product.productImage}
							onLoad={() => setFirstSectionLoadedImage(true)}
							alt=""
						/>
					</div>
				</div>
				<div className="productHeading">
					<div className="w">
						<h1>{product.productName}</h1>
						<p>{product.companyName}</p>
						<span
							className="categoryName"
							style={{
								color: 'rgb(243, 146, 0)',
							}}
						>
							{product.categoryName}
						</span>

						{product.companyDescription ? (
							<div
								className={
									'companyDescription ' +
									(companyDescriptionState.open === true
										? ' open '
										: '') +
									(companyDescriptionState.expandable === true
										? ' expandable '
										: '')
								}
								ref={companyDescriptionRef}
							>
								<div className="outer" ref={companyDescriptionOuterRef}>
									<div
										className="spacer"
										ref={companyDescriptionSpacerRef}
									/>
									<div
										className="inner"
										ref={companyDescriptionInnerRef}
										dangerouslySetInnerHTML={{
											__html: product.companyDescription,
										}}
									/>
								</div>
								<div
									className="readMore"
									onClick={companyDescriptionExpand}
								>
									+ Read More
								</div>
								<div
									className="readLess"
									onClick={companyDescriptionCollapse}
								>
									- Less
								</div>
							</div>
						) : (
							''
						)}

						{/* l'anchor può avere class: (link+) --> btn | btnSmall | inline*/
						product.extUrl ? (
							<a href={product.extUrl} className="link btn">
								Download company profile
							</a>
						) : (
							''
						)}
					</div>
				</div>
			</section>
			<section
				className="productGallery bgLightOrange topCut"
				data-sectionname="productGallery"
			>
				<div className="w">
					<h2 className="sectionTitle">Media Gallery</h2>

					{videos.length > 0
						? videos.map((url) => {
								//const pieces = url.split('/');								
								//const vimeoId = pieces[pieces.length - 2];
								/*
                                console.log(
									url,
									vimeoId,
									'https://player.vimeo.com/video/' + vimeoId,
                                );
                                */
								return (
									<div className="video" key={url}>
										<Vimeo
											video="437045788"
											showByline={false}
											showTitle={false}
											responsive={true}
											onPlay={() =>
												setVideoState({
													...videoState,
													videoPlaying: true,
												})
											}
											onPause={() =>
												setVideoState({
													...videoState,
													videoPlaying: false,
												})
											}
											onEnd={() =>
												setVideoState({
													...videoState,
													videoPlaying: false,
												})
											}
											onTimeUpdate={(timing) => {
												setVideoState({
													...videoState,
													percent: timing.percent * 100,
												});
											}}
											paused={!videoState.videoPlaying}
										/>
									</div>
								);
						  })
						: null}
					<Masonry
						className={'masonryGallery'} // default ''
						elementType={'ul'} // default 'div'
						options={{ transitionDuration: 0 }} // default {}
						disableImagesLoaded={false} // default false
						updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
						imagesLoadedOptions={{}}
					>
						{images.length > 0 &&
							images.map((img, index) => {
								return (
									<li className="item" key={img}>
										<div>
											<img
												src={img}
												onClick={() =>
													setLightboxState({
														...lightboxState,
														isOpen: true,
														photoIndex: index,
													})
												}
												alt="" 
											/>
										</div>
									</li>
								);
							})}
					</Masonry>
					{lightboxState.isOpen ? (
						<Lightbox
							mainSrc={images[lightboxState.photoIndex]}
							nextSrc={
								images[(lightboxState.photoIndex + 1) % images.length]
							}
							prevSrc={
								images[
									(lightboxState.photoIndex + images.length - 1) %
										images.length
								]
							}
							onCloseRequest={() =>
								setLightboxState({ ...lightboxState, isOpen: false })
							}
							onMovePrevRequest={() =>
								setLightboxState({
									...lightboxState,
									photoIndex:
										(lightboxState.photoIndex + images.length - 1) %
										images.length,
								})
							}
							onMoveNextRequest={() =>
								setLightboxState({
									...lightboxState,
									photoIndex:
										(lightboxState.photoIndex + 1) % images.length,
								})
							}
						/>
					) : (
						''
					)}
				</div>
			</section>

			<section
				className="productPrice topCut"
				data-sectionname="productPrice"
			>
				<div className="w">
					{hasPrice(product) ? (
						<>
							<h2 className="sectionTitle">Price Simulator</h2>

							<div className="table">
								<div className="row">
									<div className="col">
										<span>Currency</span>
										<div className="switch" {...priceSwipeHandlers}>
											<div
												className="selector"
												ref={currencySwitcheSelectorRef}
											/>
											<div
												className="choice"
												onClick={() =>
													setPriceSimulatorInput({
														...priceSimulatorInput,
														currency: event.currency,
													})
												}
											>
												{event.currency}
											</div>
											<div
												className="choice"
												onClick={() =>
													setPriceSimulatorInput({
														...priceSimulatorInput,
														currency: event.otherCurrency,
													})
												}
											>
												{event.otherCurrency}
											</div>
										</div>
									</div>
								</div>
								<div className="row heading">
									<div className="col">
										<h3>Sell In Quote</h3>
										<p>{product.priceStandards}</p>
									</div>
								</div>
								<div className="row field">
									<div className="col">Selling Unit Format</div>
									<div className="col input">
										<div className="pre">
											{product.sellingUnit} <strong>x</strong>{' '}
										</div>
										<FormGroup className={'int'}>
											<FormControl
												type="number"
												value={priceSimulatorInput.items || ''}
												onFocus={(event) => event.target.select()}
												onChange={(evt) => {
													if (evt.target.value === '')
														setPriceSimulatorInput({
															...priceSimulatorInput,
															items: 0,
														});
													else {
														const number = Number(
															evt.target.value,
														);
														setPriceSimulatorInput({
															...priceSimulatorInput,
															items: number,
														});
													}
												}}
											/>
										</FormGroup>
									</div>
								</div>

								<div className="row field">
									<div className="col">
										Seller Price per Selling Unit
									</div>
									<div className="col output">
										<div className="value">
											{(
												priceSimulatorOutput.spi *
												priceSimulatorInput.items
											).toFixed(2)}
										</div>
										<div className="post">
											{priceSimulatorOutput.currencySymbol}
										</div>
									</div>
								</div>
								<div className="row field">
									<div className="col">Seller Price per Kg/l</div>
									<div className="col output">
										<div className="value">
											{priceSimulatorOutput.spkg.toFixed(2)}
										</div>
										<div className="post">
											{priceSimulatorOutput.currencySymbol}
										</div>
									</div>
								</div>
								<div className="row field">
									<div className="col">
										Logistics &amp; Duties Costs
									</div>
									<div className="col input">
										<FormGroup bsSize="lg">
											<FormControl
												type="number"
												value={
													priceSimulatorInput.cotscoPercentage ||
													''
												}
												className="smaller"
												onFocus={(event) => event.target.select()}
												onChange={(evt) => {
													if (evt.target.value === '')
														setPriceSimulatorInput({
															...priceSimulatorInput,
															cotscoPercentage: 0,
														});
													else {
														const number = Number(
															evt.target.value,
														);
														setPriceSimulatorInput({
															...priceSimulatorInput,
															cotscoPercentage: Math.min(
																number,
																100,
															),
														});
													}
												}}
											/>
										</FormGroup>
										<div className="post">%</div>
									</div>
								</div>
							</div>

							<div className="table">
								<div className="row heading">
									<div className="col">
										<h3 className="divider less-bottom">
											Retailer margins
										</h3>
									</div>
								</div>
								<div className="row field">
									<div className="col">
										Gross Price per Selling Unit
									</div>
									<div className="col output">
										<div className="value">
											{(
												priceSimulatorOutput.sellerPlusLogisticPrice *
												priceSimulatorInput.items
											).toFixed(2)}
										</div>
										<div className="post">
											{priceSimulatorOutput.currencySymbol}
										</div>
									</div>
								</div>
								<div className="row field">
									<div className="col">Markup</div>
									<div className="col input">
										<FormGroup>
											<FormControl
												type="number"
												value={priceSimulatorInput.markup || ''}
												className="smaller"
												onFocus={(event) => event.target.select()}
												onChange={(evt) => {
													if (evt.target.value === '')
														setPriceSimulatorInput({
															...priceSimulatorInput,
															markup: 0,
														});
													else {
														const number = Number(
															evt.target.value,
														);
														setPriceSimulatorInput({
															...priceSimulatorInput,
															markup: Math.min(number, 100),
														});
													}
												}}
											/>
										</FormGroup>
										<div className="post">%</div>
									</div>
								</div>
							</div>
							<div className="table">
								<div className="row heading">
									<div className="col">
										<h3 className="divider less-bottom">
											Sell Out Projection
										</h3>
									</div>
								</div>
								<div className="row field">
									<div className="col">
										Shelf Price per Selling Unit
									</div>
									<div className="col output">
										<div className="value">
											{(
												priceSimulatorOutput.rpPerItem *
												priceSimulatorInput.items
											).toFixed(2)}
										</div>
										<div className="post">
											{priceSimulatorOutput.currencySymbol}
										</div>
									</div>
								</div>
								<div className="row field">
									<div className="col">Shelf Price Per Kg/L</div>
									<div className="col output">
										<div className="value">
											{priceSimulatorOutput.rpPerKg.toFixed(2)}
										</div>
										<div className="post">
											{priceSimulatorOutput.currencySymbol}
										</div>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<h2 className="sectionTitle">Price Details</h2>

							<p className="center">
								The product's company handles the price within a direct
								negotiation.
								<br />
								Please contact <strong>
									{product.companyName}
								</strong>{' '}
								for further informations.
							</p>
						</>
					)}
				</div>
			</section>

			<section
				className="productFAQ bgLightOrange topCut"
				ref={FAQsectionRef}
				data-sectionname="productFAQ"
			>
				<div className="w">
					<h2 className="sectionTitle">FAQ</h2>

					{faq.length === 0 && (
						<p className="center">
							You can{' '}
							<a href={'mailto:' + event.supportEmail}>
								ask our staff members
							</a>{' '}
							for further informations
						</p>
					)}
					{faq.map((q, i) => {
						return (
							<div className="question-block" key={q.question}>
								<div
									className="question"
									onClick={(e) => {
										openFAQAccordion(i);
									}}
								>
									<span>{q.question}</span>
									<div className="icon">
										<span />
										<span />
									</div>
								</div>
								<div className="expander">
									<span
										dangerouslySetInnerHTML={{
											__html: q.answer.replace(/\\r/g, '<br />'),
										}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</section>
			<section
				className="productFeedback topCut"
				data-sectionname="productFeedback"
			>
				<div className="w">
					<h2 className="sectionTitle">Samples and Feedback</h2>
					<p>
						Ask the supplier a sample, give us your feedback, help us and
						the company to improve
					</p>
					<div className="ctas">
						<div
							className="btn"
							onClick={() => {
								setPageState({
									...pageState,
									iframeOverlayOpen: true,
								});
							}}
						>
							Start
						</div>
					</div>
				</div>
			</section>
			<OverlayIframe
				pageState={pageState}
				setPageState={setPageState}
				url={form}
				dataQuerystring={{
					first_name: userData.name,
					last_name: userData.surname,
					email: userData.email,
					title: userData.title,
					product_name: product.productName,
					company_name: product.companyName,
				}}
			/>
			<section className="backToList bgRainforest topCut">
				<div className="w">
					<Link to={`/${hash}/search`}>&lt; Go home</Link>
				</div>
			</section>
		</>
	);
};
